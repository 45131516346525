<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <locale />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-capitalize">
              {{ name }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="profilePicture"
            :text="avatarText(name)"
          >
            <feather-icon v-if="!name" icon="UserIcon" size="22" />
          </b-avatar>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'account-setting' }"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>{{ $t("button.setting") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("button.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { logout } from "@/auth/utils";
import { avatarText } from "@core/utils/filter";
import Locale from "@/layouts/components/app-navbar/Locale.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
    name: function () {
      if (this.$store.state.profile.name) {
        return this.$store.state.profile.name;
      }

      return this.userData?.name;
    },
    department: function () {
      if (this.$store.state.profile.department) {
        return this.$store.state.profile.department;
      }

      return this.userData?.department;
    },
    roles: function () {
      if (this.$store.state.profile.roles) {
        return this.$store.state.profile.roles;
      }

      return this.userData?.roles;
    },
    profilePicture: function () {
      if (this.$store.state.profile.image) {
        return this.$store.state.profile.image;
      }

      return this.userData?.image;
    },
  },
  data() {
    return {
      avatarText,
    };
  },
  methods: {
    logout() {
      logout();

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
  },
};
</script>
