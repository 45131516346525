import dashboard from "./menu/dashboard";
import marketplaceHeader from "./menu/marketplaceHeader";
import configurationHeader from "./menu/configurationHeader";
import vehicle from "./menu/vehicle";
import vehicleConfiguration from "./menu/vehicleConfiguration";
import setting from "./menu/setting";
import user from "./menu/user";
import dealer from "./menu/dealer";
import auctionSchedule from "./menu/auctionSchedule";
import marketplace from "./menu/marketplace";
import payment from "./menu/payment";
import report from "./menu/report";
import guideline from "./menu/guideline";
import configuration from "./menu/configuration";

export default [
  ...dashboard,
  ...vehicle,
  ...marketplace,
  ...payment,
  ...dealer,
  ...user,
  ...configuration,
]
